<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>
        <v-col cols="5" lg="2">
            <v-select :items="list_pays" v-model="selectpays" dense outlined label="Liste des pays">
                <template slot="selection" slot-scope="data">
                    <v-img :src="data.item.logo" alt="data.item.id" width="50px"></v-img> {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    <v-img :src="data.item.logo" alt="data.item.id"></v-img> {{ data.item.libelle }}
                </template>


            </v-select>
        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1" color="primary">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES BLACKLIST CLIENTS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog=true">
                        <v-icon dark>mdi-plus</v-icon> Nouveau
                    </v-btn>

                </template>

                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Formulaire</v-card-title>
                        <v-card-text>

                            <v-select :items="list_monnaire" v-model="selectmonnaire" item-value="id" dense outlined label="Liste des monnaire">
                                <template slot="selection" slot-scope="data">
                                    <v-img :src="data.item.logo" alt="data.item.id" width="50px"></v-img> {{ data.item.libelle_monnaie }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <v-img :src="data.item.logo" alt="data.item.id"></v-img> {{ data.item.libelle_monnaie }}
                                </template>

                            </v-select>
                            <v-text-field v-model="libelle_key" label="Code client" outlined></v-text-field>
                            <v-text-field v-model="libelle_adresse" label="Adresse" outlined></v-text-field>
                            <v-textarea v-model="observation" label="Raison" outlined></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2" max-width="400">

                    <v-card>
                        <v-card-title class="text-h5">Suppression</v-card-title>
                        <v-card-text>
                            <h3> Voulez vous vraiment supprimer blacklist ?</h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog2=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItem()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>

                </v-dialog>

            </v-toolbar>
        </template>
        <template v-slot:item.user="{ item }">
            <div>
                <div>Email : {{ item.user.email }}</div>
                <div>
                    <h3>Code : {{ item.user.key }}</h3>
                </div>
            </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detailsup(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="detail(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>

    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Gestion des clients",
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '5px'

            },
            {

                text: "ID",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '50px'
            },

            {

                text: "Date operation",
                align: "start",
                sortable: false,
                value: "date_enregistrement",
                divider: true,
                width: '50px'
            },
            {

                text: "Information client",
                align: "start",
                sortable: false,
                value: "user",
                divider: true,
                width: '250px'
            },
            {

                text: "Adresse de compte",
                align: "start",
                sortable: false,
                value: "account",
                divider: true,
                width: '150px'
            },

            {
                text: "Monnaies",
                align: "start",
                sortable: false,
                value: "currencie",
                divider: true,
                width: '150px'
            },
            {
                text: "Observation",
                align: "start",
                sortable: false,
                value: "msg",
                divider: true,
                width: '150px'
            },

            {
                text: "Pays",
                value: "pays",
                divider: true,
                width: '10px'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            nom: '',
            prenom: '',
            email: '',
            code: '',
            message: '',
        },

        list: [],
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],

        list_monnaire: [],

        valeur: '',
        etat: false,
        libelle_recherche: '',

        id_transaction: '',
        libelle_key: '',
        libelle_adresse: '',
        observation: '',
        selectmonnaire: '',
        libelle_amount: '',
        id: '',
        etat: false,

    }),

    created() {
        this.readAll();
        this.readAll_monnaire();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('colonne', this.selecttype_recherche == 'CODE CLIENT' ? 'key' : 'email');
            fd.append('valeur', this.libelle_recherche);
            const data = await api.createUpdatedata('backoffice/all-blacklist', fd);
            if(data.status==false){
                this.showAlert(data.message,'error');
            }
            this.list = data.data;

        },
        readAll_recherche: async function () {
            let fd = new FormData();
            fd.append('colonne', this.selecttype_recherche == 'CODE CLIENT' ? 'key' : 'email');
            fd.append('valeur', this.libelle_recherche);
            const data = await api.createUpdatedata('backoffice/all-blacklist', fd);
            this.list = data.data;

        },
        readAll_monnaire: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-monnaie', fd);
            this.list_monnaire = data;

            let fd2 = new FormData();
            const data2 = await api.createUpdatedata('backoffice/all-pays', fd2);
            this.list_pays = data2;

            //const data2= await api.createUpdatedata('backoffice/all-pays');

        },
        deleteItem: async function () {
            let fd = new FormData();

            const data = await api.createUpdatedata('backoffice/delete-blacklist/' + this.id, fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.readAll();
            } else {
                this.showAlert(data.message, 'error');
            }
        },
        clearItem() {
            this.selectmonnaire = 0;
            this.libelle_key = '';
            this.libelle_adresse = '';
            this.observation = '';

        },
        save: async function () {
            let fd = new FormData();
            fd.append('id_monnaire', this.selectmonnaire);
            fd.append('key', this.libelle_key);
            fd.append('adresse', this.libelle_adresse);
            fd.append('observation', this.observation);
            fd.append('amount', this.libelle_amount);

            if (this.etat == true) {
                const data = await api.createUpdatedata('backoffice/update-blacklist/' + this.id, fd);
                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.readAll();
                    this.dialog = false;
                    clearItem();
                } else {
                    this.showAlert(data.message, 'error');
                }
            } else {
                const data = await api.createUpdatedata('backoffice/add-blacklist', fd);
                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.readAll();
                    this.dialog = false;
                    clearItem();
                } else {
                    this.showAlert(data.message, 'error');
                }
            }

        },

        detail: async function (item) {
            this.id = item.id;
            this.observation = item.msg;
            this.selectmonnaire = item.currencie.id;
            this.libelle_key = item.user.key;
            this.libelle_adresse = item.account;
            this.etat = true;

            this.dialog = true;
        },
        detailsup: async function (item) {
            this.id = item.id;

            this.dialog2 = true;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
